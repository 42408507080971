<template>
  <div class="course-info" v-wechat-title="courseDetail.courseName">
    <span class="course-info-banner">
      <v-img :attachment-id="courseDetail.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')" />
    </span>
    <div class="course-info-head">
      <div>
        <strong>{{courseDetail.courseName}}</strong>
        <p><span>{{courseDetail.viewed}}人</span>浏览</p>
        <div>
          <img src="@/assets/img/kcls.png" alt="kcbanner" /> 主讲人：{{courseDetail.coursePresentecoursePresenter}}
        </div>
        <div>
          <img src="@/assets/img/kcdw.png" alt="kcbanner" />
          主讲单位：{{courseDetail.courseCompanyName}}
        </div>
      </div>
    </div>
    <div class="course-info-tag">
      <span :class="{ active: courseTagIndex == 1 }" @click="courseTagIndex = 1">课程介绍</span>
      <span :class="{ active: courseTagIndex == 2 }" @click="courseTagIndex = 2">课程安排</span>
    </div>
    <div class="course-info-box">
      <course-introduce v-if="courseTagIndex == 1" :desc="courseDetail.description"/>
      <course-list v-if="courseTagIndex == 2" :id="courseDetail.id" :list="courseDetail.crsCourseChapters"/>
      <div class="course-introduce-recommend">
        <p>更多推荐</p>
        <div class="course-list-box">
          <div v-for="(item, index) in courseList" :key="index" @click="courseInfoBtn(item.id)">
            <p>
              <span v-if="item.isOnTop"><img src="@/assets/img/tjtb.png" alt="tjtb" /></span>
              <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')" />
            </p>
            <div>
              <span>{{item.courseName}}</span>
              <p>
                <span>共{{item.chapterTotal}}节课</span>
                <span>{{item.viewed?item.viewed:0}}人浏览</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course-info-notice-box" v-if="chapterInfo != ''">
      <van-notice-bar mode="closeable">
        <div class="course-info-notice">
          <span>继续上次课程</span>
          <van-button type="warning" size="mini" @click.stop="videoUrlBtn">立即观看</van-button>
        </div>
      </van-notice-bar>
    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import courseIntroduce from "./courseIntroduce.vue";
import courseList from "./courseList.vue";
import { getCourseInfo,getCourseList } from "@/api/activity";
import VImg from "@/components/VImg";
import share from '../components/share.vue'
export default {
  components: {
    courseIntroduce,
    courseList,
    VImg,
    share
  },
  data() {
    return {
      courseTagIndex: 1,
      courseDetail: "",
      courseList: [],
      shareInfo: {
          title: '',
          desc: '',
          img: require('@/assets/img/logoinimg.png')
      },
      chapterInfo:''
    };
  },
  computed: {
    id: function () {
      return this.$route.query.id;
    },
    chapterId: function () {
      return this.$route.query.chapterId;
    },
  },
  watch: {
    id() {
      this.courseInfo();
    },
  },
  mounted() {
    this.courseInfo();
    this.loadGetCourseList()
  },
  methods:{
    videoUrlBtn(){
      let {chapterInfo} = this
      window.open(`${chapterInfo.videoUrl}`,'_blank')
    },
    // 详情
    courseInfo() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      getCourseInfo(this.id).then((res) => {
        this.$toast.clear();
        this.shareInfo.title = res.courseName
        this.shareInfo.desc = res.summary
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.courseCoverAttmId}`
        this.courseDetail = res;
        this.loadchapter()
      });
    },
    loadchapter(){
      let {courseDetail,chapterId} = this
      if(!chapterId){
        return
      }
      let result = courseDetail.crsCourseChapters.filter(el=> {
        return el.id == chapterId
      })
      this.chapterInfo = result[0]
    },
     // 列表
    loadGetCourseList(){
      let page = {
        current: 1,
        size: 4,
      }
      getCourseList(page,{}).then((res)=>{
        this.courseList = res.records
      })
    },
    courseInfoBtn(id){
      this.$router.push({
        path: "/course/info",
        query: { id },
      });
      // this.courseInfo();
    }
  }
};
</script>

<style lang="scss" scoped>
.course-info {
  min-height: 100%;
  background: #f6f6f6;
  .course-info-banner {
    height: 210px;
    display: block;
    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .course-info-head {
    padding: 0 16px;
    position: relative;
    margin-top: -54px;
    z-index: 2;
    > div {
      background: #fff;
      padding: 16px 14px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      > strong {
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        margin-bottom: 8px;
      }
      > p {
        display: flex;
        align-items: flex-end;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        margin-bottom: 10px;
        > span {
          font-size: 16px;
          line-height: 20px;
          color: #EA0B06;
          margin-right: 2px;
        }
      }
      > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 17px;
        color: #333333;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        > img {
          width: 14px;
          margin-right: 6px;
        }
      }
    }
  }
  .course-info-tag {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #eee;
    > span {
      height: 50px;
      border-bottom: 2px solid transparent;
      margin-right: 90px;
      font-size: 14px;
      color: #333;
      line-height: 50px;
      &.active {
        color: #EA0B06;
        border-color: #EA0B06;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .course-info-box {
    padding: 10px 16px;
    .course-introduce-recommend {
      background: #ffffff;
      border-radius: 4px;
      padding: 16px 12px;
      margin-bottom: 16px;
      > p {
        padding-left: 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #333333;
        border-left: 3px solid #ea0b06;
        margin-bottom: 24px;
      }
      .course-list-box {
        > div {
          margin-top: 16px;
          background: #fff;
          border-radius: 4px;
          display: flex;
          > p {
            width: 140px;
            height: 78px;
            margin-right: 12px;
            position: relative;
            margin-bottom: 0;
            > img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 4px;
            }
            > span {
              position: absolute;
              left: 4px;
              top: -4px;
              width: 32px;
              > img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
          }
          > div {
            flex: 1;
            > span {
              display: block;
              font-size: 14px;
              line-height: 20px;
              color: #333333;
              word-break: break-all;
              height: 40px;
              overflow: hidden;
            }
            > p {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0;
              margin-top: 21px;
              > span {
                font-size: 12px;
                line-height: 17px;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}
.course-info-notice-box{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.course-info-notice{
  display: flex;
  align-items: center;
  >button{
    margin-left: 12px;
  }
}
</style>
