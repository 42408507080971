<template>
  <div class="course-introduce">
    <div class="course-introduce-test">
      <p>课程介绍</p>
      <div v-html="desc"></div>
    </div>
  </div>
</template>

<script>
export default {
  props:['desc']
};
</script>

<style lang="scss" scoped>
.course-introduce {
  > div {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px 12px;
    margin-bottom: 16px;
    > p {
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: #333333;
      border-left: 3px solid #ea0b06;
      margin-bottom: 16px;
    }
  }
  .course-introduce-test {
    > div {
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }
  
}
</style>