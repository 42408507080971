<template>
  <div class="course-arrange">
    <div class="course-list-box">
      <p>课程介绍</p>
      <div class="course-list">
        <template v-for="(item,index) in list">
          <div v-if="index < showNum" :key="item.id" :class="{'active':courseId == item.id}" @click.stop="videoUrl(item.videoType,item.videoUrl,item.id)">
            <span>
              <img src="@/assets/img/kcsp.png" class="course-icon1" alt="课程" />
              <img
                src="@/assets/img/5080@2x.png"
                class="course-icon2"
                alt="课程"
              />
            </span>
            <p>{{item.chapterName}}</p>
          </div>
        </template>
      </div>
      <span v-if="showNum != list.length" @click="showNum == 4?showNum = list.length:showNum = 4">查看全部</span>
    </div>


  </div>


</template>

<script>
import { courseSignUp } from "@/api/activity";


export default {
  props:['list','id'],
  data(){
    return{
      courseId:'',
      showNum:4
    }
  },
  methods:{
    videoUrl(type,url,id){
      if(type==2){
        let data = {
          chapterId:id,
          courseId:this.id
        }
        this.courseId = id
        courseSignUp(data).then((res)=>{})
        window.open(`${url}`,'_blank')
      }
      if(type == 1){
        this.$router.push({
          path: "/course/video",
          query: { url },
        });

      }

    }
   }
};
</script>

<style lang="scss" scoped>
.course-arrange {
  .course-list-box {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px 12px 1px;
    margin-bottom: 16px;
    > p {
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: #333333;
      border-left: 3px solid #ea0b06;
      margin-bottom: 16px;
    }
    .course-list {
      > div {
        display: flex;
        margin-bottom: 16px;
        > span {
          height: 20px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          > img {
            width: 14px;
            height: 12px;
            display: block;
          }
          .course-icon1 {
            display: none;
          }
        }
        > p {
          flex: 1;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
        }
        &.active {
          > span {
            .course-icon1 {
              display: block;
            }
            .course-icon2 {
              display: none;
            }
          }
          >p{
            color: #EA0B06;
          }
        }
      }
    }
    >span{
        display: block;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-top: 1px solid #eee;
        font-size: 14px;
        color: #666;
    }
  }
}

#myvideo{
  position: absolute;
}
</style>
